.gallery-section {
    padding-top: 140px;
    margin-bottom: 80px;
}

.gallery-section h1 {
    padding-top: 40px;
    font-family: 'Questrial', sans-serif;
    font-size: 2.8em;
    text-align: center;
}

.gallery-section hr {
    border-color: rgb(214, 214, 214);
    width: 10%;
    margin: auto;
    margin-top: 15px;
}

.gallery {
    width: 80%;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
}

.gallery-image-card {
    padding: 5px;
    border: 1px solid #dddd;
    border-radius: 2px;
}

.gallery-image {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.gallery-tags {
    text-align: center;
    padding: 40px 0px;
}

.tag-button {
    outline: none;
    cursor: pointer;
    background-color: rgb(238, 238, 238);
    font-family: 'Questrial', sans-serif;
    font-size: 1.2em;
    outline: none;
    border: none;
    padding-top: 12px;
    padding-bottom: 8px;
    padding-left: 14px;
    padding-right: 14px;
}

.tag-button:hover {
    background-color: rgb(216, 216, 216);
}

.tag-active {
    background-color: rgb(216, 216, 216);
}

/* Media Query (Gallery Page) */
@media (max-width: 767.5px) {
    .gallery-section {
        padding-top: 110px;
    }
}

@media (max-width: 1200px) {
    .gallery {
        width: 100%;
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 800px) {
    .gallery {
        width: 100%;
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 576px) {
    .gallery-tags {
        width: 80%;
        margin: auto;
    }
}