@import url('https://fonts.googleapis.com/css2?family=Questrial&family=Source+Sans+Pro:ital,wght@0,300;0,400;0,600;0,700;0,900;1,300;1,400;1,600;1,900&display=swap');

.header {
    position: absolute;
    z-index: 0;
    width: 100%;
    margin-top: -65vh;
    text-align: center;
}

.header h1 {
    font-family: 'Questrial', sans-serif;
    font-size: 4.2em;
    text-transform: uppercase;
    color: #ffff;
    text-shadow: 0px 0px 4px #000000;
}

.header button {
    font-family: 'Questrial', sans-serif;
    color: #ffff;
    background: #a5c3b9;
    border: none;
    box-shadow: 0px 0px 1.5px #000000;
    padding: 5px 20px 5px 20px;
    font-size: 1.4em;
}

.header button:hover {
    background: #96b6ab;
    transition: all 0.2s ease-out;
}

#main-img {
    z-index: 1;
    margin-top: 130px;
    width: 100%;
    height: 100vh;
    object-fit: cover;
}

/* Media Query (Carousel) */
@media (max-width: 1080px) {
    .header h1 {
        font-size: 3.5em;
    }
}

@media (max-width: 900px) {
    .header {
        margin-top: -66vh;
    }
    .header h1 {
        font-size: 4em;
    }
    #main-img {
        margin-top: 80px;
    }
}

@media (max-width: 767.5px) {
    .header h1 {
        font-size: 3.8em;
    }
}

@media (max-width: 550px) {
    .header h1 {
        font-size: 3em;
    }
}