.about {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    position: relative;
    background-color: #ffff;
    z-index: 2;
    padding: 0;
    box-shadow: 0px 0px 3px 0.3px;
    margin-top: -50px;
    max-width: 1200px;
}

.about1 {
    width: 100%;
    background-color: #a5c3b9;
    padding-top: 15px;
    padding-bottom: 5px;
    text-align: center;
}

.about1 .title {
    font-family: 'Questrial', sans-serif;
    color: #ffff;
    font-size: 2.8em;
}

.about5 {
    width: 100%;
}

.about5 img {
    width: 100%;
}

.about2 {
    flex: 1;
    flex-basis: 30em;
    padding: 30px;
    
}

.about3 {
    flex: 1;
    flex-basis: 20em;
    padding: 30px;
}

.about4 {
    flex: 1;
    flex-basis: 22em;
    padding: 30px;
}

.about2 h1, .about3 h1, .about4 h1 {
    font-family: 'Questrial', sans-serif;
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 2em;
}

.about2 p, .about3 p, .about4 p {
    font-family: 'Questrial', sans-serif;
    font-size: 1.2em;
}

/* Media Query (About-Section) */
@media (max-width: 1080px) {
    .about2 {
        flex: 1;
        flex-basis: 30em;
        padding-bottom: 0;
        text-align: center;
    }

    .about3 {
        flex: 1;
        flex-basis: 20em;
        padding: 30px;
        padding-bottom: 0;
        text-align: center;
    }

    .about4 {
        flex: 1;
        flex-basis: 22em;
        padding: 30px;
        text-align: center;
    }

    .about5 img {
        content: url(images/aa-ashley3.jpg);
    }
}

@media (max-width: 900px) {
    .about {
        margin-top: -20px;
    }
}

@media (max-width: 767.5px) {
    .about5 img {
        content: url(images/aa-ashley2.jpg);
    }
    .about {
        margin-top: -50px;
    }
}