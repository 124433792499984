@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,700');

.navbar {
    position: fixed;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #a5c3b9;
    z-index: 3;
    margin-top: 50px;
}

.fa-bars {
    color: white;
    font-size: 2.2em;
    display: none;
}

.fa-times {
    color: white;
    font-size: 2.2em;
    display: none;
}

.nav-menu {
    display: grid;
    justify-content: end;
    grid-template-columns: repeat(4, auto);
    grid-gap: 0;
    list-style: none;
    text-align: center;
    padding-top: 15px;
    width: 70vw;
}

.call-quote1 {
    display: none;
}

.call-quote2 p {
    visibility: hidden;
    font-family: 'Questrial', sans-serif;
    color: white;
    background:rgb(88, 88, 88);
    font-size: 1.2em;
    padding-top: 2.5px;
    padding-left: 20px;
    height: 35px;
    width: 100%;
    float: left;
    position: fixed;
    z-index: 3;
}

.top-bar {
    display: flex;
    font-family: 'Questrial', sans-serif;
    color: white;
    background:rgb(88, 88, 88);
    padding-left: 10%;
    padding-right: 11%;
    font-size: 1.2em;
    height: 50px;
    width: 100%;
    position: fixed;
    z-index: 3;
}

.top-bar a {
    text-decoration: none;
    color: white;
}

.top-contact1 {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding-top: 11px;
}

.top-contact2 {
    display: none;
}

.top-contact3 {
    display: none;
}

#phone-icon1 {
    margin-right: 20px;
    margin-left: 3px;
    width: 20px;
}

#phone-icon2 {
    margin-left: 3px;
    width: 20px;
}

#email-icon {
    margin-left: 7px;
    width: 22px;
}

.top-social {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
    width: 100%;
}

#google-nav, #fb-nav {
    width: 25px;
    height: 25px;
}

#angies-nav {
    width: 34px;
    height: 25px;
}

.logo {
    justify-self: start;
    width: 150px;
    cursor: pointer;
}

#nav-link {
    text-decoration: none;
    margin: 10px;
}

.navbar .link {
    cursor: pointer;
    text-decoration: none;  
    font-size: 1.2em;
    padding-top: 7px;
    padding-bottom: 10px;
    padding-left: 12px;
    padding-right: 12px;
    color: white;
}

.navbar .link:hover {
    color: white;
    background-color: #92b1a7;
    transition: all 0.2s ease-out;
}

.active-link {
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #92b1a7;
}

/* Media Query (Navbar) */
@media (max-width: 1920px) {
    .top-bar {
        padding-left: 11%;
        padding-right: 11.9%;
    }
}

@media (max-width: 1740px) {
    .top-bar {
        padding-left: 10.5%;
        padding-right: 11.5%;
    }
}

@media (max-width: 1680px) {
    .top-bar {
        padding-left: 10.4%;
        padding-right: 11.4%;
    }
}

@media (max-width: 1536px) {
    .top-bar {
        padding-left: 10%;
        padding-right: 11.05%;
    }
}

@media (max-width: 1440px) {
    .top-bar {
        padding-left: 9.5%;
        padding-right: 10.75%;
    }
}

@media (max-width: 1366px) {
    .top-bar {
        padding-left: 9.2%;
        padding-right: 10.5%;
    }
}

@media (max-width: 1250px) {
    .top-contact1 {
        display: none;
    }
    .top-contact2 {
        visibility: visible;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        padding-top: 11px;
    }
    .call-quote1 {
        visibility: visible;
    }
}

@media (max-width: 1150px) {
    .top-bar {
        padding-left: 8.2%;
        padding-right: 9.65%;
    }
}

@media (max-width: 1000px) {
    .top-bar {
        padding-left: 7.2%;
        padding-right: 8.8%;
    }
}

@media (max-width: 920px) {
    .top-bar {
        padding-left: 6.4%;
        padding-right: 8.3%;
    }
}

@media (max-width: 860px) {
    .top-contact2 {
        display: none;
    }

    .top-contact3 {
        visibility: visible;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        padding-top: 11px;
    }
}

@media (max-width: 820px) {
    .top-bar {
        padding-left: 5.2%;
        padding-right: 7.5%;
    }
}

@media (max-width: 767.5px) {
    .navbar {
        margin-top: 35px;
    }

    .top-bar {
        display: none;
    }

    .call-quote2 p {
        visibility: visible;
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        position: absolute;
        transition: all 0.5s ease;
        grid-template-columns: repeat(4, auto);
        grid-gap: 0px;
        width: 100%;
        height: 235px;
        top: 60px;
        left: -100%;
        opacity: 1;
    }

    .nav-menu.active {
        background: #a5c3b9;
        left: 0;
        opacity: 1;
        transition: all 0.5 ease;
        z-index: 1;
    }

    .logo {
        position: absolute;
        width: 120px;
        top: 0;
        left: 0;
        transform: translate(25%, 80%);
    }

    #nav-link {
        margin: 0;
    }

    .navbar .link {
        text-align: center;
        width: 100%;
        padding-top: 12px;
        padding-bottom: 15px;
        display: table;
    }

    .navbar .link:hover {
        background-color: #92b1a7;
        border-radius: 0;
    }

    .fa-bars {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-70%, 70%);
        cursor: pointer;
    }

    .fa-times {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 70%);
        cursor: pointer;
    }
}