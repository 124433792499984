/* Form Styling */
.modal-form input[type=text], .modal-form input[type=email], .modal-form input[type=url], .modal-form input[type=file], .modal-form select, .modal-form textarea {
    width: 100%; /* Full width */
    padding: 5px; /* Some padding */ 
    box-sizing: border-box; /* Make sure that padding and width stays in place */
    margin-top: 0px; /* Add a top margin */
    margin-bottom: 10px; /* Bottom margin */
    resize: vertical /* Allow the user to vertically resize the textarea (not horizontally) */
}

.modal-bg {
    position: fixed;
    margin-top: 75px;
    width: 100%;
    height: 100%;
    min-height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-wrapper {
    position: absolute;
    box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
    background: #fff;
    color: #0000;
    z-index: 10;
    height: 600px;
}

@media (min-width: 1920px) {
    .modal-wrapper {
        width: 30%; 
    }
}
@media (max-width: 1920px) {
    .modal-wrapper {
        width: 30%; 
    }
}
@media (max-width: 1680px) {
    .modal-wrapper {
        width: 30%; 
    }
}
@media (max-width: 1440px) {
    .modal-wrapper {
        width: 40%; 
    }
}
@media (max-width: 1080px) {
    .modal-wrapper {
        width: 60%; 
    }
}
@media (max-width: 800px) {
    .modal-wrapper {
        width: 80%; 
    }
}


@media (max-height: 720px) {
    .modal-wrapper {
        height: 500px;
        overflow-y: scroll;
    }
}
@media (max-height: 650px) {
    .modal-wrapper {
        height: 400px;
        overflow-y: scroll;
    }
}
@media (max-height: 550px) {
    .modal-wrapper {
        height: 300px;
        overflow-y: scroll;
    }
}
@media (max-height: 450px) {
    .modal-wrapper {
        height: 200px;
        overflow-y: scroll;
    }
}
@media (max-height: 350px) {
    .modal-wrapper {
        height: 100px;
        overflow-y: scroll;
    }
}
@media (max-height: 250px) {
    .modal-wrapper {
        height: 50px;
        overflow-y: scroll;
    }
}

.modal-button {
    color: black;
    margin: 0;
    padding: 0;
    font-size: 1.8em;
    cursor: pointer;
    top: -279px;
    float: right;
    z-index: 10;
}

.modal-contact-form {
    color: black;
    background-color: #ffff;
    height: auto;
}

.modal-contact-title {
    padding-top: 35px;
    text-align: center;
    width: 100%;
    font-family: 'Questrial', sans-serif;
    color: rgb(0, 0, 0);
    font-size: 2.2em;
}

.modal-contact-submit {
    width: 100%;
    font-size: 1em;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 0;
    border-style: none;
    background-color: #a5c3b9;
    color: #ffff;
}

.modal-contact-submit:hover {
    background-color: #96b6ab;
}

.modal-contact-info h3 {
    color: rgb(0, 0, 0);
    font-family: 'Questrial', sans-serif;
    font-size: 1.2em;
    text-align: center;
}

.modal-contact-info a {
    text-decoration-color: rgb(0, 0, 0);
}

.modal-contact-info hr {
    border-color: rgb(214, 214, 214);
    width: 10%;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 30px;
}

.modal-form label {
    font-size: 1em;
}

.modal-form span {
    padding-left: 2px;
    color: red;
}

.modal-notify-contact {
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;
    font-weight: 500;
    font-family: 'Questrial', sans-serif;
    font-size: 1em;
}