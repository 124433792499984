@media (min-width: 1241px) {
    .services-section1 {
        display: block;
    }
    .services-section2 {
        display: none;
    }
}

@media (max-width: 1240px) {
    .services-section1 {
        display: none;
    }
    .services-section2 {
        display: block;
    }
}